/* eslint-disable require-jsdoc */
'use strict';
var $pageThemeColor = '';
var base = require('./product/base');
var isMobile = !($(window).width() >= 768);

$(document).ready(function () {
    var pageNameToSetColor = $('#theme-color-top').html();
    function setPageTopColor(page) {
        // if (page === 'profile') {
        //     base.changeThemeColor('#27067F');
        // } else if (page === 'tickets') {
        //     base.changeThemeColor('#FF463A');
        // } else if (page === 'wallet') {
        //     base.changeThemeColor('#009C06');
        // } else if (page === 'wishlist') {
        //     base.changeThemeColor('#CC00D1');
        // }
        // else
        if (page === 'profile-new') {
            base.changeThemeColor('#FF00EE');
        } else if (page === 'landing') {
            base.changeThemeColor('#01A3FF');
        } else if (page === 'zoomLanding') {
            base.changeThemeColor('#aa026a');
        } else if (page === 'channel-landing') {
            base.changeThemeColor('#043ADF');
        } else if (page === 'fitness-landing') {
            base.changeThemeColor('#2f2e2b');
        } else if (page === 'madness-landing') {
            base.changeThemeColor('#2f2e2b');
        } else if (page === 'noon-landing') {
            base.changeThemeColor('#AF0208');
        } else if (page === 'noon1million-landing') {
            base.changeThemeColor('#ffee04');
        } else if (page === 'dfcStage') {
            base.changeThemeColor('#ffdf07');
        } else if (page === 'modeshRetail') {
            base.changeThemeColor('#91cbcc');
        } else if (page === 'chattels') {
            base.changeThemeColor('#ac9670');
        } else if (page === 'launch') {
            base.changeThemeColor('#FF545A');
        } else if (page === 'whatsapp-launch') {
            base.changeThemeColor('#EEEEEE');
        } else if (page === 'noon-valentine') {
            base.changeThemeColor('#ec165c');
        } else if (page === 'auto-pro') {
            base.changeThemeColor('#000000');
        } else {
            base.changeThemeColor('#b206f0');
        }
    }
    setPageTopColor(pageNameToSetColor);

    $(document).on('click', '.js-switcher-toggle', function (e) {
        e.preventDefault();
        $('.header-switcher').toggleClass('open-switcher');
    });

    $(document).on('click', '.js-switcher-closer', function (e) {
        e.preventDefault();
        $('.header-switcher').removeClass('open-switcher');
    });

    $(document).on('click', '.header-switcher', function (e) {
        if (
            $(e.target).hasClass('header-switcher') &&
            !$(e.target).hasClass('.switcher-item')
        ) {
            $('.header-switcher').removeClass('open-switcher');
        }
    });

    $(document).on('click', '.js-menu-button', function (e) {
        e.preventDefault();
        // $.spinner().start();
        $('body').toggleClass('navOpen');
        $('.off-canvas-menu').toggleClass('open');
        $pageThemeColor = $('meta[name=theme-color]').attr('content');
        // base.changeThemeColor('#10101A');
        // $(this).toggleClass("open");
    });

    $(document).on('click', '.js-mobile-menu-button', function (e) {
        e.preventDefault();
        $('body').addClass('navOpen');
        $('.off-canvas-menu').addClass('open');
        $pageThemeColor = $('meta[name=theme-color]').attr('content');
    });

    $(document).on('click', '.js-close-menu-button', function (e) {
        e.preventDefault();
        $('body').removeClass('navOpen');
        $('.off-canvas-menu').removeClass('open');
        $pageThemeColor = $('meta[name=theme-color]').attr('content');
    });


    $(document).on('click', '.js-setting-icon', function (e) {
        e.preventDefault();
        $('.offCanvas-menu').animate({
            scrollTop: $('.setting-header').position().top
        }, 500);
    });
    $(document).on('click', '.blockerCanvas', function (e) {
        e.preventDefault();
        $('body').removeClass('navOpen');
        $('.off-canvas-menu').removeClass('open');
        base.changeThemeColor($pageThemeColor);
    });

    $(document).on('click', '.js-language-link', function () {
        // $(document).trigger('globalDropdown:show', 'languageDropDown');
        $('.menu-language-selector').addClass('show');
    });
    $(document).on('click', '.js-currency-link', function () {
        $('.menu-currency-selector').addClass('show');
        // $(document).trigger('globalDropdown:show', 'currencyDropDown');
    });
    $(document).on('click', '.js-back-lang', function () {
        // $(document).trigger('globalDropdown:show', 'languageDropDown');
        $('.menu-language-selector').removeClass('show');
    });
    $(document).on('click', '.js-back-currency', function () {
        // $(document).trigger('globalDropdown:show', 'currencyDropDown');
        $('.menu-currency-selector').removeClass('show');
    });

    $('body').on('click', '.js-trigger-cart', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.attr('disabled') === 'disabled') {
            return;
        }
        var $parent = $this.closest('.js-cart-feature');
        var currentQuantity = $(this).find('.js-qty-text').text();
        currentQuantity = currentQuantity ? parseInt(currentQuantity, 10) : 0;
        // dynamically inject ul li for adding product to cart
        var maxQty = 999;
        var multipleEntry = null;
        try {
            var qtys = $parent
                .find('.js-dynamic-dropdown')
                .attr('data-quantities');
            multipleEntry = $parent
                .find('.js-dynamic-dropdown')
                .attr('data-multipleentry');
            var qtysArr = JSON.parse(qtys);
            var multipleTextEntry = $parent
                .find('.qty-unicorn')
                .attr('data-multipleentrytext');
            if ($parent.find('.js-multipleEntryText').length) {
                $parent
                    .find('.js-multipleEntryText')
                    .html(
                        multipleTextEntry
                            .replace('#count', multipleEntry)
                            .replace('#br', '<br/>')
                    );
            }
            maxQty = qtysArr[qtysArr.length - 1];
            // eslint-disable-next-line no-empty
        } catch (e) {}
        // generate ul li
        if (isMobile) {
            var dropdDownHtml =
            '<div class="swiper-quantity-init"><ul class="swiper-wrapper js-dynamically-created">';
                // eslint-disable-next-line radix
            var isValidMultipleEntry =
                    multipleEntry &&
                    multipleEntry !== 'null' &&
                    parseInt(multipleEntry) <= maxQty;
            for (var i = 0; i < maxQty; i++) {
                dropdDownHtml +=
                        '<li class="swiper-slide ' +
                        (i + 1 === currentQuantity ? 'active' : '') +
                        // (i + 1 === 1 && !isValidMultipleEntry ? 'active' : '') +
                        ' ' +
                        (isValidMultipleEntry &&
                        parseInt(i + 1) === parseInt(multipleEntry) &&
                        parseInt(multipleEntry) > currentQuantity
                            ? 'multipleEntry'
                            : '') +
                        '"><a href="javascript:;" data-value="' +
                        (i + 1) +
                        '">' +
                        (i + 1).toFixed(0) +
                        '</a></li>';
            }
        } else {
            var dropdDownHtml =
                '<div class="quantity-wrap"><div class="swiper-button-prev"></div><div class="swiper-button-next"></div><div class="swiper-quantity-init swiper"><ul class="swiper-wrapper js-dynamically-created">';
            // eslint-disable-next-line radix
            var isValidMultipleEntry =
                multipleEntry &&
                multipleEntry !== 'null' &&
                parseInt(multipleEntry) <= maxQty;
            for (var i = 0; i < maxQty; i++) {
                dropdDownHtml +=
                    '<li class="swiper-slide ' +
                    (i + 1 === currentQuantity ? 'active' : '') +
                     ' ' +
                    // (i + 1 === 1 && !isValidMultipleEntry ? 'active' : '') +
                    // ' ' +
                    (isValidMultipleEntry &&
                    parseInt(i + 1) === parseInt(multipleEntry) &&
                    parseInt(multipleEntry) > currentQuantity
                        ? 'multipleEntry'
                        : '') +
                    '"><a href="javascript:;" data-value="' +
                    (i + 1) +
                    '">' +
                    (i + 1).toFixed(0) +
                    '</a></li>';
            }
        }

        if (isValidMultipleEntry) {
            $parent
                .find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)')
                .addClass('d-none');
        }
        dropdDownHtml += '</ul></div></div>';
        if (isMobile) {
            $parent.find('.swiper-quantity-init').remove();
        } else {
            $parent.find('.quantity-wrap').remove();
        }
        $parent.find('.js-dynamic-dropdown').after(dropdDownHtml);
        $parent.find('.qty-unicorn, h6.small').removeClass('hide');
        $('.qtyActive').removeClass('qtyActive');
        $parent.addClass('qtyActive');
        if (isMobile) {
            $('body').addClass('scroll-lock');
        }
        $(document).trigger('addToCartModule:open');
        $(this)
            .parents('.product-detail')
            .find('.cart-quantity.input-number')
            .val(1);
        $(document).trigger('initQuantitySwiper:open', { initialSlide: currentQuantity });
    });

    $('body').on('click', '.js-initial-add-to-cart', function (e) {
        e.preventDefault();
        $(document).trigger('addToCartModule:open');
        $(this).closest('.product-detail').find('.cart-quantity').val(1);
        $(this).closest('.product-detail').find('.js-global-new-add-cart').trigger('click');
    });


    $('body').on('click', '.deleteQty', function (e) {
        e.preventDefault();
        $(document).trigger('addToCartModule:open');
    });

    $('body').on('click', '.js-credit-info', function (e) {
        var $this = $(this);
        var $parent = $this.closest('.js-cart-feature');
        $('body').addClass('scroll-lock');
        $parent.addClass('creditInfoActive');
    });

    $('body').on('click', '.js-close-credit-info', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.closest('.js-cart-feature');
        $('body').removeClass('scroll-lock');
        $parent.removeClass('creditInfoActive');
    });

    $('body').on('mouseup', function (e) {
        var container = $('.new-quantity-dropper-wrapper');
        var $parent = $(e.target).closest('.js-cart-feature');
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $parent.removeClass('qtyActive');
            $parent.removeClass('creditInfoActive');
            $parent.find('.js-dynamically-created').remove();
            $('body').removeClass('scroll-lock');
            // container.find('li:first-child').addClass('active').siblings().removeClass('active');
        }
    });

    $('body').on('click', '.js-qty-selector ul a', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parent();
        var $val = $this.data('value');

        $this
            .closest('.new-quantity-dropper-wrapper')
            .find('.cart-quantity')
            .val($val);

        try {
            var multipleEntry = $this
                .closest('.new-quantity-dropper-wrapper')
                .find('.js-dynamic-dropdown')
                .attr('data-multipleentry');
            var multipleTextEntry = $this
                .closest('.new-quantity-dropper-wrapper')
                .find('.qty-unicorn')
                .attr('data-multipleentrytext');
            var multipleTextBtn = $this
                .closest('.new-quantity-dropper-wrapper')
                .find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)')
                .attr('data-multiple-text-btn');
            var normalTextBtn = $this
                .closest('.new-quantity-dropper-wrapper')
                .find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)')
                .attr('data-text');
            if (parseInt(multipleEntry) <= parseInt($val)) {
                $this
                    .closest('.new-quantity-dropper-wrapper')
                    .find('.qty-unicorn,h6.small')
                    .addClass('hide');
                $this
                    .closest('.new-quantity-dropper-wrapper')
                    .find(
                        '.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)'
                    )
                    .text(normalTextBtn)
                    .removeClass('d-none');
            } else {
                $this
                    .closest('.new-quantity-dropper-wrapper')
                    .find('.qty-unicorn,h6.small')
                    .removeClass('hide');
                $this
                    .closest('.new-quantity-dropper-wrapper')
                    .find('.js-multipleEntryText')
                    .html(
                        multipleTextEntry
                            .replace('#count', parseInt(multipleEntry - $val))
                            .replace('#br', '<br/>')
                    );
                $this
                    .closest('.new-quantity-dropper-wrapper')
                    .find(
                        '.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)'
                    )
                    .text(
                        multipleEntry !== 'null'
                            ? multipleTextBtn.replace('#count', $val)
                            : normalTextBtn
                    )
                    .removeClass('d-none');
            }
            // eslint-disable-next-line no-empty
        } catch (e) {}

        if (!isMobile) {
            $this.closest('.new-quantity-dropper-wrapper').find('.js-global-new-add-cart').trigger('click');
        }
        $parent.addClass('active').siblings().removeClass('active');
    });

    $('#languageSelector .language').on('click', function () {
        var $cur = $(this).closest('ul');
        var selectedVal = $(this).data('value');
        $cur.attr('data-selected', selectedVal);
        var $previousVal = $cur.attr('data-prev-selected');

        if (selectedVal !== $previousVal) {
            var action = $('.page').data('action');
            var localeCode = selectedVal;
            // var localeCurrencyCode = '$('.js-currency-select [type=radio]:checked').val()';
            var localeCurrencyCode =
                $('#currencySelector').attr('data-selected');

            var queryString = $('.page').data('querystring');
            var url = $cur.data('url');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: 'Home-Show'
                },
                success: function (response) {
                    var gTagData = {
                        localeCode: localeCode
                    };
                    $(document).trigger('country:success', gTagData);
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $('#currencySelector .currency').on('click', function () {
        var $cur = $(this).closest('ul');
        var selectedVal = $(this).data('value');
        $cur.attr('data-selected', selectedVal);
        var $previousVal = $cur.attr('data-prev-selected');
        if (selectedVal !== $previousVal) {
            var action = $('.page').data('action');
            var localeCode = $('#languageSelector').attr('data-selected');
            // var localeCurrencyCode = '$('.js-currency-select [type=radio]:checked').val()';
            var localeCurrencyCode = selectedVal;
            var queryString = $('.page').data('querystring');
            var url = $cur.data('url');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: 'Home-Show'
                },
                success: function (response) {
                    var gTagData = {
                        localeCurrencyCode: localeCurrencyCode
                    };
                    $(document).trigger('currency:success', gTagData);
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
});
